const datePatten = (e) => {
    let code = e.keyCode;
    let allowedKeys = [8];
    if (allowedKeys.indexOf(code) !== -1) {
        return;
    }

    e.target.value = e.target.value
        .replace(
            /^([1-9]\/|[2-9])$/g,
            "0$1/" // 3 > 03/
        )
        .replace(
            /^(0[1-9]|1[0-2])$/g,
            "$1/" // 11 > 11/
        )
        .replace(
            /^([0-1])([3-9])$/g,
            "0$1/$2" // 13 > 01/3
        )
        .replace(
            /^(0?[1-9]|1[0-2])([0-9]{2})$/g,
            "$1/$2" // 141 > 01/41
        )
        .replace(
            /^([0]+)\/|[0]+$/g,
            "0" // 0/ > 0 and 00 > 0
        )
        .replace(
            /[^\d\/]|^[\/]*$/g,
            "" // To allow only digits and `/`
        )
        .replace(
            /\/\//g,
            "/" // Prevent entering more than 1 `/`
        );
};


const creditCardValidation = (creditCradNum, SetValidCardNumber) => {


    let regEx_MasterCard = /^5[1-5][0-9]{14}$|^2(?:2(?:2[1-9]|[3-9][0-9])|[3-6][0-9][0-9]|7(?:[01][0-9]|20))[0-9]{12}$/;

    let regEx_Visa = /^4[0-9]{12}(?:[0-9]{3})?$/;

    let regEx_AmericanExpress = /^3[47][0-9]{13}$/;

    let regEx_DinersClub = /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/;

    let regEx_Discover = /^6(?:011|5[0-9]{2})[0-9]{12}$/;

    let regEx_JCB = /^(?:2131|1800|35\d{3})\d{11}$/;

    if(
        (creditCradNum.target.value.match(regEx_MasterCard) ||
            creditCradNum.target.value.match(regEx_Visa) ||
            creditCradNum.target.value.match(regEx_AmericanExpress) ||
            creditCradNum.target.value.match(regEx_DinersClub) ||
            creditCradNum.target.value.match(regEx_Discover) ||
            creditCradNum.target.value.match(regEx_JCB)) && creditCradNum.target.value.length > 10
    )
    {
        SetValidCardNumber(true);
        return true;
    }
    else
    {
        SetValidCardNumber(false);
        return false;
    }
}


const donorNameLatinCharValidation = (str, SetValidCardName) => {

    const hasOnlyLettersAndSpace = /^[A-Za-z\s]*$/.test(str.target.value);

    if (hasOnlyLettersAndSpace) {
        SetValidCardName(true);
        return true;
    } else {
        SetValidCardName(false);
        return false;
    }
}

export {datePatten, creditCardValidation, donorNameLatinCharValidation};
import "./styles.css";
import CreditCard from "./CreditCard";
import {AppHeader} from "./AppHeader";

export default function App() {
  return (
    <div className="App">
        <AppHeader />
        <CreditCard />
    </div>
  );
}

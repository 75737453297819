import React, { useState,useEffect } from "react";
import axios from 'axios';
import { datePatten, creditCardValidation, donorNameLatinCharValidation } from "./helpers";
import "bootstrap/dist/css/bootstrap.min.css";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import styles from "./styles.css";

const CreditCard = () => {
  const queryParameters = new URLSearchParams(window.location.search)
  const opportunity = queryParameters.get("opp");

  const [number, SetNumber] = useState("");
  const [validCardNumber, SetValidCardNumber] = useState(true);
  const [name, SetName] = useState("");
  const [validCardName, SetValidCardName] = useState(true);
  const [date, SetDate] = useState("");
  const [cvc, SetCvc] = useState("");
  const [token, SetToken] = useState("");
  const [processType, SetProcessType] = useState(null);
  const [amount, SetAmount] = useState({
      formattedAmount: "",
      totalAmount: 0
  });
  const [focus, SetFocus] = useState("");
  const [processStatus, SetProcessStatus] = useState("none");
  const [loading, SetLoading] = useState(false);

    const GetData = () => {
        SetLoading(true);
        const req_url = window.env.REACT_APP_API_BASE_URL + '/salesforce/getOppInformation';
        return axios
            .post(req_url, {
                processTitle: "get opp information",
                recordId: opportunity,
                username: window.env.REACT_APP_API_USERNAME,
                password: window.env.REACT_APP_API_PASSWORD
            })
            .then((response) => {

                SetToken(response.data.jwtToken.access_token);

                if (response.data.sfdcRes[0].StageName === "Gift") {
                    SetProcessStatus("paid");
                }

                //// Set the process type (one time or recurring)
                SetProcessType(response.data.sfdcRes[0].Payment_Method__c);

                //// Set the name of the donor in the card name field
                SetName(response.data.sfdcRes[0].Contact__r.FirstName + " " + response.data.sfdcRes[0].Contact__r.LastName);
                donorNameLatinCharValidation({target: {value: response.data.sfdcRes[0].Contact__r.FirstName + " " + response.data.sfdcRes[0].Contact__r.LastName}},SetValidCardName);

                //// Defined amount is the amount that the user chose in the donation form
                const definedAmountEntity = response.data.sfdcRes[0].Payment_Method__c === 'Recurring' ? response.data.sfdcRes[0].Recurring_Amount_with_Processing_Fee__c : response.data.sfdcRes[0].Amount;
                const formattedAmount = (definedAmountEntity).toLocaleString('en-US', {
                    style: 'currency',
                    currency: window.env.REACT_APP_CURRENCY
                });
                SetAmount({formattedAmount: formattedAmount, totalAmount: definedAmountEntity});
                SetLoading(false);
                return response.data;
            }).catch((err) => console.log(err));

    };





    const handleSubmit = (e) => {
        e.preventDefault();
        SetProcessStatus("processingPayment");
        SetLoading(true);
        const req_url = processType === "Recurring" ? window.env.REACT_APP_API_BASE_URL + '/process/createFirstRecurringPayment' : window.env.REACT_APP_API_BASE_URL + '/process/chargeSinglePayment';
        const data = JSON.stringify({
            "processTitle": processType === "Recurring" ? "createFirstRecurringPayment" : "chargeSinglePayment",
            "opportunityId": opportunity,
            "cardNumber": number,
            "cvvNumber": cvc,
            "expMonth": parseInt(date.slice(0,2)),
            "expYear": parseInt(date.slice(-2))+2000,
            "amount": amount.totalAmount,
            "nameOnCard": name,
            "currency": window.env.REACT_APP_CURRENCY,
        });

        return axios
            .post(req_url, data,
                {
                    headers: {
                        "Authorization": "Bearer " + token,
                        "Content-Type": "application/json",
                    },
                })
            .then((response) => {

                if(response.data.redirectUrl) {

                    window.location.href = response.data.redirectUrl;

                } else {

                    SetLoading(false);
                    SetProcessStatus(response.data.paymentStatus);

                }

                //return response.data;
            }).catch((err) => console.log(err));

    }


  useEffect(() => {

      GetData();

  }, []);





  return (
    <>

        {loading &&
            <div className="LoadingContainer">
            <div className="loader">
                <img src="/images/hartmanLogo.png"/>
            </div>
            <div className="loaderMsg">{(processStatus !== "processingPayment" || processStatus === "none") ? "Loading payment details..." : "Processing payment, please wait..."}</div>
            </div>
        }

        {processStatus === "succeeded" || processStatus === "paid" && <div className="successPayment">
            {processType && processType === "Recurring" ?
                <div className="successPaymentStatus">{"Donation monthly amount"}</div> :
                <div className="successPaymentStatus">{"Thank you for your past donation"}</div>
            }

            <div className="successPaymentCTA">
                <a href="https://hartman.org.il" className="ctaHartman">Go to Hartman Website</a>
            </div>
        </div>}


        {(processStatus !== "succeeded" && processStatus !== "paid") &&
            <div className={"paymantSectionContainer"}>
                <div className={"PaymentDetailsContainer"}>
                    <div className="donationAmountCont">
                        {processType && processType === "Recurring" ?
                            <div className="donationAmountTitle">{"Total Monthly Donation"}</div> :
                            <div className="donationAmountTitle">{"Total Donation"}</div>
                        }
                        <div className="donationAmountLabel">{amount.formattedAmount}</div>
                    </div>
                    <Cards
                        number={number}
                        name={name}
                        expiry={date}
                        cvc={cvc}
                        focused={focus}
                    />
                </div>
                <div className="paymentFormContainer">
                    <form onSubmit={handleSubmit}>
                        <div className="formTitle">Your Payment Details</div>

                        <div className="donationAmountMobileCont">
                            {processType && processType === "Recurring" ?
                                <div className="donationAmountTitle">{"Total Monthly Donation"}</div> :
                                <div className="donationAmountTitle">{"Total Donation"}</div>
                            }
                            <div className="donationAmountLabel">{amount.formattedAmount}</div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className={"cardOnNameTitle"}>Name on Card</div>
                                <input
                                    type="text"
                                    className="form-control cardName"
                                    value={name}
                                    name="name"
                                    onInput={(e) => donorNameLatinCharValidation(e, SetValidCardName)}
                                    onChange={(e) => {
                                        SetName(e.target.value);
                                    }}
                                    onFocus={(e) => SetFocus(e.target.name)}
                                ></input>
                            </div>
                            {!validCardName && <div className="validCardName">Please provide name in English</div>}
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className={"cardNumberTitle"}>Card Number</div>
                                <input
                                    type="text"
                                    className="form-control cardNumber"
                                    placeholder={"xxxx xxxx xxxx xxxx"}
                                    value={number}
                                    name="number"
                                    onChange={(e) => {
                                        SetNumber(e.target.value);
                                    }}
                                    onInput={(e) => creditCardValidation(e, SetValidCardNumber)}

                                    onFocus={(e) => {
                                        SetFocus(e.target.name)
                                    }}
                                ></input>
                            </div>
                            <div className="cardNotValid">{validCardNumber ? null : "card not valid"}</div>
                        </div>

                        <br/>
                        <div className="d-flex ccDetails">
                            <div className="">
                                <div className={"expirationDateTitle"}>Expiration Date</div>
                                <input
                                    type="text"
                                    name="expiry"
                                    className="form-control expirationDate"
                                    placeholder={"MM/YY"}
                                    value={date}
                                    onChange={(e) => {
                                        datePatten(e);
                                        SetDate(e.target.value);
                                    }}
                                    onFocus={(e) => SetFocus(e.target.name)}
                                ></input>
                            </div>
                            <div className="ml-auto">
                                <div className={"ccvTitle"}>CVV</div>
                                <input
                                    type="tel"
                                    name="cvc"
                                    placeholder={"CVV"}
                                    className="card cvv"
                                    value={cvc}
                                    onChange={(e) => {
                                        SetCvc(e.target.value);
                                    }}
                                    onFocus={(e) => SetFocus(e.target.name)}
                                ></input>
                            </div>
                        </div>
                        {processStatus !== "succeeded" && processStatus !== "none" && processStatus!=="processingPayment" && <div className="failedPaymentStatus">Payment system message: {processStatus.replaceAll("_"," ").toUpperCase()}</div>}
                        <div className="row">
                            <div className="col-sm-12">
                                <button className="btn btn-primary paymentBtn" type="submit">Pay Now</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        }

    </>
  );
};
export default CreditCard;
